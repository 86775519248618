var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "grey--text text--lighten-1"
    }, [_vm._v(_vm._s(item.title) + " " + _vm._s(item.info))])]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }