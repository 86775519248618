import axios from "@/plugins/axios";

let getBaseUrl = ({ _program }) => `/api/console/services/${_program}/applications`;

export const applications = {
    gets(data) {
        return axios.get(getBaseUrl(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${getBaseUrl(data)}/${data._id}`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${getBaseUrl(data)}/${data._id}`, data).then((result) => result.data);
    },
};
