export const initStatisticsMinutes = (value = 0, separator = "\r\n") => {
    let text = "";

    let hour = Math.floor(value / 60);
    if (hour) text += `${hour.format()}시간`;
    let minute = value % 60;
    if (text && minute) text += separator;
    if (minute) text += `${minute}분`;

    return text || "-";
};
