<template>
    <div class="wrap" id="head">
        <!-- 헤더 -->
        <main-header />

        <!-- 메인 -->
        <v-main class="main">
            <slot />
        </v-main>

        <!-- 푸터 -->
        <main-footer />
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        MainFooter,
    },
    data: () => ({}),
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 240,
                    duration: 800,
                    throttleDelay: 100,
                    easing: "ease-out",
                    anchorPlacement: "top-center",
                });
            }, 100);
        },
    },
};
</script>
