<template>
    <div>
        <v-row justify="center" class="row--small">
            <template v-for="item in items">
                <v-col cols="auto" :key="item.name">
                    <router-link :to="item.link" class="white--text">
                        {{ item.name }}
                    </router-link>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "이용약관",
                link: "/",
            },
            {
                name: "개인정보처리방침",
                link: "/",
            },
        ],
    }),
};
</script>