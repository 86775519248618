import { MANAGER_SCOPES } from "../../assets/variables";

export const PopupRoutes = [
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/popup/PopupList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.popups.value],
    },
];
