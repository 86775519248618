<template>
    <header class="header">
        <div class="header-primary-container">
            <div class="header-primary">
                <div class="header-primary__contents">
                    <div class="header-primary__forward">
                        <div class="header-logo">
                            <h1>
                                <router-link to="/" title="홈으로 이동">
                                    <span class="blind">금산군 농촌신활력 플러스사업</span>
                                </router-link>
                            </h1>
                        </div>
                        <!-- Mobile 햄버거 버튼 -->
                        <div class="mobile-menu">
                            <button type="button" aria-pressed="false" class="button">
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div class="pc-menu">
                        <v-row no-gutters align="center" justify="space-between" class="h-100">
                            <v-col cols="auto">
                                <client-gnb className="gnb" class="pl-140"></client-gnb>
                            </v-col>
                            <v-col cols="auto">
                                <gnb-login-button class="header-customer-lists" />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile sidebar -->
        <div class="mobile-sidebar">
            <div class="mobile-sidebar__head">
                <button class="mobile-sidebar__close" type="button"><span class="blind">모바일 메뉴바 닫기</span></button>
            </div>
            <div class="mobile-sidebar__link">
                <gnb-login-button class="mobile-gnb-link" />
            </div>
            <div class="mobile-sidebar__body">
                <client-gnb className="gnb-mo"></client-gnb>
            </div>
        </div>
        <div class="mobile-sidebar-dimmed"></div>
    </header>
</template>

<script>
// import api from "@/api";

import { Header } from "@/plugins/header.js"; // Sticky & Heaer 스크립트
import GnbLoginButton from "@/components/client/login/gnb-login-button.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    components: {
        GnbLoginButton,
        ClientGnb,
    },
    data: () => ({}),
    mounted() {
        this.header();
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
    },
    methods: {
        header: function () {
            this.$nextTick(function () {
                Header(); // Sticky & Heaer 스크립트 로드
            });
        },
        alert(message) {
            alert(message);
        },
    },
    computed: {
        isMainPage() {
            return this.$route.path === "/";
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    background-color: #fff;
    transition: all ease-out 0.3s;
    .header-primary-container {
        background-color: inherit;
        .header-primary {
            background-color: inherit;
            border-bottom: 1px solid;
            border-color: var(--v-grey-lighten4);
        }
    }
}
.pc-menu {
    display: none;
}
@media (min-width: 1464px) {
    .pc-menu {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    // fullpage Main
    .fp-viewing-0 .header {
        background-color: transparent;
        .header-primary-container {
            background-color: inherit;
            .header-primary {
                background-color: inherit;
                border-bottom: 1px solid;
                border-color: rgba(255, 255, 255, 0.2);
            }
        }
        ::v-deep {
            .header-menu-lists {
                .header-menu-list {
                    .link {
                        color: #fff;
                    }
                }
            }
            .header-customer-lists {
                .v-btn {
                    color: #fff;
                }
            }
        }
        .header-logo {
            > h1 {
                > a {
                    background-image: url(/images/ci-w.svg);
                }
            }
        }

        &:hover {
            background-color: #fff;
            .header-logo {
                > h1 {
                    > a {
                        background-image: url(/images/ci.svg);
                    }
                }
            }

            ::v-deep {
                .header-menu-lists {
                    .header-menu-list {
                        &:hover {
                            .link {
                                color: var(--v-primary-base);
                            }
                        }
                        .link {
                            color: var(--v-grey-darken4);
                        }
                    }
                }
                .header-customer-lists {
                    .v-btn {
                        color: var(--v-grey-darken4);
                    }
                }
            }
        }
    }
}
</style>