<template>
    <footer class="footer grey darken-4 page-text page-text--sm white--text">
        <v-container class="container--lg">
            <v-img src="/images/ci-footer.svg" max-width="300" alt="금산군 농촌신활력 플러스사업 추진단" class="mx-auto mb-18 mb-md-24" />

            <footer-sns class="mb-12 mb-md-16" />

            <footer-link class="mb-4 mb-md-8" />

            <footer-information />

            <p class="grey--text text--lighten-1 text-center mt-8 mt-md-16">Copyright 2022. COMPANY CO.,Ltd. All Rights Reserved.</p>
        </v-container>
    </footer>
</template>

<script>
import FooterSns from "./main-footer/footer-sns.vue";
import FooterLink from "./main-footer/footer-link.vue";
import FooterInformation from "./main-footer/footer-information.vue";

export default {
    components: {
        FooterSns,
        FooterLink,
        FooterInformation,
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer {
    padding: 40px 0;
}
@media (min-width: 768px) {
    .footer {
        padding: 60px 0;
    }
}
</style>