<template>
    <div class="icon-link-arrow">
        <template v-for="item in 2">
            <v-icon :color="arrowColor" :key="item">mdi-chevron-right</v-icon>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        white: { type: Boolean, default: false },
    },
    computed: {
        arrowColor() {
            return this.white ? "white" : "black";
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-link-arrow {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 22px;
    .v-icon {
        position: absolute;
        top: 0;
        opacity: 0;
        animation: ToRight 2s infinite;
        &:first-child {
            left: 0;
            animation-delay: 0.2s;
        }
        &:last-child {
            right: 0;
        }
    }
}

@keyframes ToRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(8px);
        opacity: 0;
    }
}
</style>