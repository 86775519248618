import axios from "@/plugins/axios";

let url = "/api/console/mes/materials";
import dummy from "./dummy.json";

export default {
    gets(data) {
        // return axios.get(url, data).then(result => result.data);

        let { skip = 0, limit = 10 } = data?.headers || {};
        let { searchKey, searchValue } = data?.params || {};

        return new Promise((resolve, reject) => {
            let materials = dummy.filter((item) => {
                if (searchValue) {
                    let regex = new RegExp(searchValue, "i");

                    switch (!!searchKey) {
                        case true:
                            return regex.test(item[searchKey]);
                        case false:
                            return ["type", "name", "code", "vendor"].some((searchKey) => regex.test(item[searchKey]));
                    }
                } else return true;
            });
            let summary = { totalCount: materials.length };
            materials = materials.splice(skip, limit);

            setTimeout(() => resolve({ summary, materials }), Math.floor(Math.random() * 500 + 200));
        });
    },
};
