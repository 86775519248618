export const initHistory = (history = {}) => ({
    ...history,

    index: history?.index || 0,
    shows: history?.shows || true,

    year: history?.year || null,
    month: history?.month || null,
    content: history?.content || null,
})

export const initArchivingBoard = (board = {}) => ({
    ...board,

    _thumb: board?._thumb || null,
    thumb: board?.thumb || null,

    code: board?.code || null,
    subject: board?.subject || null,
    content: board?.content || null,
    viewCount: board?.viewCount || 0,
})