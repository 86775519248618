<template>
    <v-card class="main-board-notice">
        <div class="tit d-flex align-center mb-12 mb-md-16">
            <v-img max-width="36" src="/images/icon/icon-notice.svg" class="main-board-notice__icon" />
            공지사항
        </div>

        <v-simple-table class="main-board-notice__table">
            <template #default>
                <thead>
                    <tr>
                        <th>제목</th>
                        <th>작성일</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="!boards.length">
                        <tr>
                            <td colspan="2">
                                <div class="page-text text-center">공지사항이 없습니다.</div>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <template v-for="(board, index) in boards">
                            <tr v-if="index < 4" :key="index" @click="() => $router.push(`./news/notice/${board._id}`)">
                                <td class="text-start">
                                    {{ board?.subject }}
                                </td>
                                <td>
                                    {{ $dayjs(board?.createdAt).format("YYYY-MM-DD") }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import api from "@/api";
import { BOARD_TYPES } from "@/assets/variables";
export default {
    data: () => ({
        boards: [],
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { boards } = await api.v1.boards.gets({
                    headers: { skip: 0, limit: 4 },
                    params: { code: BOARD_TYPES.NOTIFICATION.value },
                });

                this.boards = boards;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-board-notice {
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(9, 20, 48, 0.1) !important;
    padding: 18px;
    min-height: 402px;

    &__icon {
        margin-right: 4px;
    }

    &__table {
        thead {
            > tr {
                > th {
                    &:last-child {
                        width: 20%;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .main-board-notice {
        border-radius: 16px;
        padding: 24px 40px;
    }
}
</style>