import { MANAGER_SCOPES } from "../../assets/variables";

export const TermRoutes = [
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/terms/TermsList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.terms.value],
    },
];
