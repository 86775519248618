import axios from "@/plugins/axios";

let url = (data) => "/api/v1/me/programs/${_program}/units".interpolate(data);

export default {
    gets(data = {}) {
        return axios.get(url(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
