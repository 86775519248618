var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "main-board-card",
    attrs: {
      "flat": "",
      "img": _vm.img,
      "to": _vm.to
    }
  }, [_c('div', {
    staticClass: "tit font-weight-medium d-flex align-center"
  }, [_c('v-img', {
    staticClass: "main-board-card__icon",
    attrs: {
      "eager": "",
      "max-width": "60",
      "src": _vm.icon
    }
  }), _vm._v(" " + _vm._s(_vm.title) + " "), _c('icon-link-arrow')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }