import { RENTAL_LIMITATION_TARGET_TYPES } from "@/assets/variables";

export const initRentalBlockage = (blockage = {}) => ({
    ...(blockage || {}),

    endsAt: blockage?.endsAt ?? null,
    startsAt: blockage?.startsAt ?? null,

    isActive: blockage?.isActive ?? false,

    target: {
        ...(blockage?.target || {}),
        type: blockage?.target?.type ?? RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
        _subjects: [...(blockage?.target?._subjects || [])].sort(),
        subjectTypes: [...(blockage?.target?.subjectTypes || [])].sort(),
    },
});
