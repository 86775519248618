export const initUserBan = (ban = {}) => ({
    ...ban,

    _user: ban._user ?? null,

    _rentalBan: ban._rentalBan ?? null,
    _attendance: ban._attendance ?? null,

    desc: ban.desc ?? null,

    date: ban.date ?? null,
    endsAt: ban.endsAt ?? null,
    startsAt: ban.startsAt ?? null,

    count: ban.count ?? null,
    poplicy: ban.poplicy ?? null,
    rentalBan: ban.rentalBan ?? null,

    isActive: ban.isActive ?? false,

    meta: ban.meta ?? { bypass: true },
});
