import axios from "@/plugins/axios";

let url = "/api/v1/users";

export default {
    get(data) {
        return axios.get(`${url}/${data._id}`, data).then((result) => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    postBusinessRegistration(data, file){
        var formData = new FormData(); formData.append("businessRegistration", file);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/businessRegistration`, formData, { headers }).then(result => result.data);
    },
    postLogo(data, logo){
        var formData = new FormData(); formData.append("logo", logo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/logo`, formData, { headers }).then(result => result.data);
    },
    postPhoto(data, photo){
        var formData = new FormData(); formData.append("photo", photo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/photo`, formData, { headers }).then(result => result.data);
    },
    avatar: {
        post({ _user }, file) {
            var headers = { "Content-Type": "multipart/form-data" };
            var formData = new FormData();
            formData.append("file", file);
            return axios.post(`${url}/${_user}/avatar`, formData, { headers }).then((result) => result.data);
        },
        delete(data) {
            return axios.delete(`${url}/${data._user}/avatar/${data._id}`).then((result) => result.data);
        },
    },
    brochures: {
        post({ _user, index }, file) {
            var headers = { "Content-Type": "multipart/form-data" };
            var formData = new FormData();
            if (index !== undefined) formData.append("index", index);
            formData.append("file", file);
            return axios.post(`${url}/${_user}/brochures`, formData, { headers }).then((result) => result.data);
        },
        delete(data) {
            return axios.delete(`${url}/${data._user}/brochures/${data._id}`).then((result) => result.data);
        },
    },

    certify: {
        post(data){
            return axios.post(`${url}/certify`, data).then(result => result.data);
        },
    },
    recovery: {
        post(data){
            return axios.post(`${url}/recovery`, data).then(result => result.data);
        },
    },

    existence: {
        post(data){
            return axios.post(`${url}/existence`, data).then(result => result.data);
        },
    }
}
