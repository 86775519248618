<template>
    <v-container class="container--lg">
        <v-row class="row--xl">
            <v-col cols="12" xl="7" order="2" order-lg="1">
                <main-board-notice />
            </v-col>
            <v-col cols="12" xl="5" order="3" order-lg="2">
                <v-row>
                    <v-col cols="12">
                        <main-board-card title="자유게시판" icon="/images/icon/icon-free.svg" img="/images/main/boards/board-free-background.jpg" to="/news/freeboard" />
                    </v-col>
                    <v-col cols="12">
                        <main-board-card title="보도자료" icon="/images/icon/icon-press.svg" img="/images/main/boards/board-press-background.jpg" to="/news/press" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" order="1" order-lg="3">
                <v-row align="center" class="row--xl">
                    <v-col cols="auto" order="3" order-lg="1">
                        <router-link to="/news/notice" class="tit tit--sm font-weight-medium d-flex align-center">
                            더보기
                            <icon-link-arrow />
                        </router-link>
                    </v-col>
                    <v-col order="2">
                        <v-divider style="border-color: #000;" />
                    </v-col>
                    <v-col cols="12" md="auto" order="1" order-lg="3">
                        <div class="tit tit--md font-size-lg-40">
                            <div class="pointed-title">금산의 생생한 소식과</div>
                            <div>주민의 목소리를 전달합니다.</div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import IconLinkArrow from "@/components/client/icon/icon-link-arrow.vue";
import MainBoardNotice from "@/components/client/main/main-boards/main-board-notice.vue";
import MainBoardCard from "./main-boards/main-board-card.vue";

export default {
    components: {
        MainBoardNotice,
        IconLinkArrow,
        MainBoardCard,
    },
};
</script>