<template>
    <div class="main-people">
        <v-container class="container--lg">
            <v-row class="ma-n20">
                <v-col cols="12" md="6" xl="5" class="pa-20" order="2" order-lg="1">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <template v-for="item in people">
                                <div class="swiper-slide" :key="item.name">
                                    <v-card :to="`/people/${item.to}`" class="main-people-card" @mouseenter="pauseSwiper" @mouseleave="resumeSwiper">
                                        <img :src="item.img" class="main-people-card__image" />
                                        <div class="main-people-card__title tit tit--sm white-text">
                                            {{ item.name }}
                                            <icon-link-arrow white />
                                        </div>
                                    </v-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" class="pa-20" order="1" order-lg="2">
                    <div class="pt-20 pt-md-56">
                        <v-row align="center" class="row--xl">
                            <v-col cols="12" md="auto">
                                <div class="tit tit--md font-size-lg-40 pointed-title pointed-title--secondary">금산에 <strong>활력을 불어넣는 사람들</strong>을 소개합니다.</div>
                            </v-col>
                            <v-col>
                                <v-divider style="border-color: #000" />
                            </v-col>
                            <v-col cols="auto">
                                <router-link to="/" class="tit tit--sm font-weight-medium d-flex align-center">
                                    더보기
                                    <icon-link-arrow />
                                </router-link>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import people from "@/assets/data/people.json";

import iconLinkArrow from "../icon/icon-link-arrow.vue";
export default {
    components: { iconLinkArrow },
    data: () => ({
        people,
        peopleSlide: null,
    }),
    methods: {
        async init() {
            this.$nextTick(() => {
                this.peopleSlide = new Swiper(".main-people .swiper-container", {
                    allowTouchMove: true,
                    spaceBetween: 40,
                    loopedSlides: 3,
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false,
                    },
                    speed: 2500,
                    loop: true,
                    breakpoints: {
                        1200: {
                            spaceBetween: 24,
                        },
                    },
                });
            });
        },
        pauseSwiper() {
            if (this.peopleSlide) {
                this.peopleSlide.autoplay.stop();
            }
        },
        resumeSwiper() {
            if (this.peopleSlide) {
                this.peopleSlide.autoplay.start();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: visible;
        // transition-timing-function: linear;
    }
}
.main-people-card {
    border-radius: 12px;
    aspect-ratio: 586 / 392;
    max-width: 586px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    box-shadow: none !important;
    &__image,
    &__title {
        transition: all 0.3s;
    }
    &__image {
        position: absolute;
        display: block;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &__title {
        width: 100%;
        height: 101%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
    }
}
@media (min-width: 768px) {
    .main-people-card {
        border-radius: 16px;
    }
}
@media (min-width: 1440px) {
    .main-people {
        padding-top: 130px;
    }
    .main-people-card {
        &__title {
            opacity: 0;
        }
        &:hover {
            box-shadow: 10px 10px 32px 0 rgba(9, 20, 48, 0.1) !important;
            .main-people-card {
                &__image {
                    transform: translate(-50%,-50%) scale(1.1);
                }
                &__title {
                    opacity: 1;
                }
            }
        }
    }
}
</style>