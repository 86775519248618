import axios from "@/plugins/axios";

let url = "/api/v1/common/files";

export default {
    post(data = {}, file) {
        var headers = { "Content-Type": "multipart/form-data" };

        var formData = new FormData();

        const { path, index } = data;
        if (path) formData.append("path", path);
        if (index !== undefined) formData.append("index", index);

        formData.append("file", file);

        return axios.post(url, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
