var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-map"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('map-main-image', {
    staticClass: "main-map__image",
    attrs: {
      "max-width": "510",
      "mainPage": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "3",
      "order-lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "main-map__story-book",
    attrs: {
      "max-width": "342",
      "src": "/images/main/map/storybook.png"
    }
  }), _c('router-link', {
    staticClass: "tit tit--sm font-weight-medium d-flex align-center justify-center my-16 my-md-20",
    attrs: {
      "to": "./story"
    }
  }, [_vm._v(" 더보기 "), _c('icon-link-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "order": "1",
      "order-lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-md-block"
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--md text-center font-size-lg-40"
  }, [_c('div', {
    staticClass: "pointed-title"
  }, [_vm._v("금산의 260개 마을이 궁금하신가요?")]), _c('div', [_vm._v("금산 "), _c('strong', [_vm._v("신활력의 무대, 260 마을")]), _vm._v("을 소개합니다.")])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }