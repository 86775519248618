import axios from "@/plugins/axios";

let url = "/api/console/rental/statistics";

export default {
    getSummary(data) {
        return axios.get(`${url}/summary`, data).then((result) => result.data);
    },
    getSubject(data) {
        return axios.get(`${url}/subject`, data).then((result) => result.data);
    },
    getByUser(data) {
        return axios.get(`${url}/by-user`, data).then((result) => result.data);
    },
    getByTime(data) {
        return axios.get(`${url}/by-time`, data).then((result) => result.data);
    },
};
