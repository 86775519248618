import axios from "@/plugins/axios";

let url = (data) => "/api/console/rental/subjects/${_subject}/photos".interpolate(data);

export default {
    post({ _subject, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_subject) formData.append("_subject", _subject);
        if (index != undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url({ _subject })}`, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
