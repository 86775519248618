export const PROGRAM_STATES = {
    FINISHED: {
        value: "FINISHED",
        text: "교육종료",
        test: (program = {}) => dayjs().isAfter(program?.endsAt),
        match: () => ({ endsAt: { $lte: Date.now() } }),
    },
    ON_GOING: {
        value: "ON_GOING",
        text: "교육중",
        test: (program = {}) => dayjs().isBefore(program?.endsAt) && dayjs().isAfter(program?.startsAt),
        match: () => ({ startsAt: { $lte: Date.now() }, endsAt: { $gt: Date.now() } }),
    },
    UPCOMING: {
        value: "UPCOMING",
        text: "교육예정",
        test: (program = {}) => dayjs().isBefore(program?.startsAt),
        match: () => ({ startsAt: { $gt: Date.now() } }),
    },
};

export const PROGRAM_STATES__APPLICATION = {
    COMPLETE: {
        value: "COMPLETE",
        text: "모집완료",
        test: (program = {}) => program?.capacity <= program?._attendees?.length,
    },
    FINISHED: {
        value: "FINISHED",
        text: "모집종료",
        test: (program = {}) => dayjs().isAfter(program?.application?.endsAt),
    },
    ON_GOING: {
        value: "ON_GOING",
        text: "모집중",
        test: (program = {}) => dayjs().isBefore(program?.application?.endsAt) && dayjs().isAfter(program?.application?.startsAt),
    },
    UPCOMING: {
        value: "UPCOMING",
        text: "모집예정",
        test: (program = {}) => dayjs().isBefore(program?.application?.startsAt),
    },
};

export const PROGRAM_APPLICATION_STATES = {
    APPLIED: { value: "APPLIED", text: "승인대기" },
    CANCELED: { value: "CANCELED", text: "신청취소" },
    APPROVED: { value: "APPROVED", text: "승인완료" },
    REJECTED: { value: "REJECTED", text: "승인거절" },
};
