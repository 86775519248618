<template>
    <v-card flat :img="img" class="main-board-card" :to="to">
        <div class="tit font-weight-medium d-flex align-center">
            <v-img eager max-width="60" :src="icon" class="main-board-card__icon" />
            {{ title }}
            <icon-link-arrow />
        </div>
    </v-card>
</template>

<script>
import iconLinkArrow from "../../icon/icon-link-arrow.vue";
export default {
    props: {
        title: { type: String },
        icon: { type: String },
        img: { type: String },
        to: { type: String },
    },
    components: { iconLinkArrow },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.main-board-card {
    border-radius: 12px;
    padding: 18px;
    min-height: 190px;
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 8px;
    }
}
@media (min-width: 768px) {
    .main-board-card {
        border-radius: 16px;
        padding: 24px 40px;
    }
}
</style>