import { MANAGER_SCOPES } from "../../assets/variables";

export const SettingRoutes = [
    {
        // 관리자 - 팝업관리
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/setting/SettingView.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.manager.value],
    },
    {
        // 관리자 - SMTP
        path: "/console/smtp",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/setting/SmtpSettingView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 렌탈 타이틀 문구 관리
        path: "/console/rental/title",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/setting/TitleSettingView.vue"),
        props: true,
        scope: ["console"],
    },
];
