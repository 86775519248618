import axios from "@/plugins/axios";

let url = "/api/console/users/equipments";

export default {
    push(data) {
        return axios.post(`${url}/push`, data).then((result) => result.data);
    },
    pull(data) {
        return axios.post(`${url}/pull`, data).then((result) => result.data);
    },
};
