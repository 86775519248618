const digitLengthOf = (value) => value.replaceAll(/\D/g, "").length;
export const rules = {
    username: [
        (value) => !/[^a-z\d]/.test(value) || "아이디를 영문 소문자를 넣어주세요",
        (value) => /[0-9]/.test(value) || "아디이에 숫자를 넣어주세요",
        (value) => (value && value?.length > 2) || "아이디를 3자 이상으로 작성해주세요",
        (value) => (value && value?.length <= 20) || "아이디를 20자 이하로 작성해주세요",
    ],

    password: [
        (value) => /[a-z]/.test(value) || "비밀번호에 영문을 넣어주세요",
        (value) => /[~!@#$%^&*()_+|<>?:{}]/.test(value) || "비밀번호에 특수문자를 넣어주세요",
        (value) => /[0-9]/.test(value) || "비밀번호에 숫자를 넣어주세요",
        (value) =>
            (value && 8 <= value.length && value.length <= 16) ||
            "비밀번호를 8 ~ 16자 사이로 작성해주세요",
    ],

    passwordModify: [
        (value) =>
            !value || /[~!@#$%^&*()_+|<>?:{}]/.test(value) || "비밀번호에 특수문자를 넣어주세요",
        (value) => !value || /[a-zA-Z]/.test(value) || "비밀번호에 영문을 넣어주세요",
        (value) => !value || /[0-9]/.test(value) || "비밀번호에 숫자를 넣어주세요",
        (value) =>
            !value ||
            (8 <= value.length && value.length <= 16) ||
            "비밀번호를 8 ~ 16자 사이로 작성해주세요",
    ],

    name: [(value) => (value && 1 < value?.length) || "이름을 입력해주세요"],

    email: [(value) => (value && /[^@]+@[^@]+\.[^@]+/.test(value)) || "이메일을 입력해주세요"],

    phone: [
        (value) => (value && [10, 11].includes(digitLengthOf(value))) || "연락처를 입력해주세요",
    ],

    subject: [(value) => (value && 1 < value?.length) || "제목을 입력해주세요"],

    content: [(value) => (value && 1 < value?.length) || "내용을 입력해주세요"],

    promoterCode: [(value) => !!value || "추천인코드를 입력해주세요"],

    nickname: [
        (value) => (value && 2 <= value?.length) || "닉네임은 2자 이상으로 작성해주세요.",
        (value) => (value && value?.length <= 15) || "닉네임은 15자 이하로 작성해주세요.",
        (value) => (value && !/\s/g.test(value)) || "닉네임에 공백을 포함할 수 없습니다.",
        (value) =>
            (value && !/[~!@#$%^&*()_+|<>?:{}]/.test(value)) ||
            "닉네임에 특수문자를 포함시킬 수 없습니다.",
    ],
};
