export const MANAGER_SCOPES = {
    manager: { value: "manager", text: "기본권한" },
    users: { value: "users", text: "공통 - 회원" },
    archivingHistory: { value: "archivingHistory", text: "아카이빙 - 연혁" },
    archivingProject: { value: "archivingProject", text: "아카이빙 - 프로젝트" },
    archivingGallery: { value: "archivingGallery", text: "아카이빙 - 갤러리" },
    story: { value: "story", text: "이야기 - 금산마을 이야기" },
    people: { value: "people", text: "사람들 - 금산에 활력을 불어넣는 사람들" },
    boardNotification: { value: "boardNotification", text: "금산소식 - 공지사항" },
    boardCommunity: { value: "boardCommunity", text: "금산소식 - 자유게시판" },
    boardNews: { value: "boardNews", text: "금산소식 - 보도자료" },
    centerQuestions: { value: "centerQuestions", text: "관리 - 1:1문의" },
    popups: { value: "popups", text: "설정 - 팝업" },
    terms: { value: "terms", text: "설정 - 약관" },
};

export const USER_TYPES = {
    MANAGER: "USER_MANAGER",
    INDIVIDUAL: "USER_INDIVIDUAL",
    ORGANIZATION: "USER_ORGANIZATION",
};

export const INDIVIDUAL_KINDS = {
    SUPPORTER: { value: "SUPPORTER", text: "전자제조지원단" },
    STUDENT: { value: "STUDENT", text: "인력양성사업참여학생" },
    ELSE: { value: "ELSE", text: "기타" },
};

export const USER_BAN_TYPES = {
    RENTAL_EQUIPMENTS: { value: "RENTAL_EQUIPMENTS", text: "장비" },
    RENTAL_FACILITIES: { value: "RENTAL_FACILITIES", text: "시설" },
};

export const USER_GENDER_TYPES = {
    MALE: { text: "남성", value: "MALE" },
    FEMALE: { text: "여성", value: "FEMALE" },
};

export const USER_MARKETING_METHODS = {
    EMAIL: { text: "이메일", value: "EMAIL" },
    PHONE: { text: "문자", value: "PHONE" },
    BOTH: { text: "이메일+문자", value: "BOTH" },
    NONE: { text: "수신하지 않음", value: "NONE" },
};
