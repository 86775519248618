import { RENTAL_LIMITATION_TARGET_TYPES } from "@/assets/variables";

export const initRentalBanPolicy = (policy = {}) => ({
    ...policy,

    breach: {
        ...(policy.breach || {}),

        dates: policy?.breach?.dates ?? 0,
        hours: policy?.breach?.hours ?? 0,
        times: policy?.breach?.times ?? 0,
    },

    punish: {
        ...(policy.punish || {}),
        days: policy?.punish?.days ?? 1,
    },

    tempId: policy?.tempId ?? Math.random(),
});

export const initRentalBan = (ban = {}) => ({
    ...(ban || {}),

    target: {
        ...(ban?.target || {}),
        type: ban?.target?.type ?? RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
        _subjects: [...(ban?.target?._subjects || [])].sort(),
        subjectTypes: [...(ban?.target?.subjectTypes || [])].sort(),
    },

    policies: [...(ban?.policies ?? [initRentalBanPolicy()])].map(initRentalBanPolicy),

    isActive: ban?.isActive ?? false,
});
