export const initRegion = (region = {}) => ({
    ...region,

    parent: region?.parent || "",
    name: region?.name || "",
    // code: region?.code || null,
});

export const initStoryPopulation = (population = {}) => ({
    citizen: population?.citizen || null,
    household: population?.household || null,
    teenager: population?.teenager || null,
    multiCulture: population?.multiCulture || null,
    foreigner: population?.foreigner || null,
    farmer: population?.farmer || null,
});

export const initStoryGroups = (group = {}) => ({
    name: group?.name || null,
    count: group?.count || null,
    desc: group?.desc || null,
    etc: group?.etc || null,
});

export const initStoryResourcesForm = (form = {}) => ({
    desc: form?.desc || null,
    shows: form?.shows || false,
});

export const initStoryResources = (resource = {}) => ({
    economy: initStoryResourcesForm(resource?.economy),
    facility: initStoryResourcesForm(resource?.facility),
    history: initStoryResourcesForm(resource?.history),
    landscape: initStoryResourcesForm(resource?.landscape),
    village: initStoryResourcesForm(resource?.village),
    around: initStoryResourcesForm(resource?.around),
});

export const initStoryCatchphrase = (catchphrase = {}) => ({
    content: catchphrase?.content || null,
    researcher: catchphrase?.researcher || null,
});

export const initStoryImages = (image = {}) => ({
    ...image,

    _image: image?._image || null,
    image: image?.image || null,
    desc: image?.desc || null,
})
export const initStory = (story = {}) => ({
    ...story,

    _region: story?._region || null,
    title: story?.title || null,
    researchedAt: story?.researchedAt || null,
    phone: story?.phone || null,
    history: story?.history || null,
    population: initStoryPopulation(story?.population),

    groups: story?.groups || [],

    resources: initStoryResources(story?.resources),
    catchphrase: initStoryCatchphrase(story?.catchphrase),
    images: story?.images || [],
});
