export const STORY_REGION_TYPES = {
    BOKSU: { text: "복수면", value: "BOKSU", x: "172.74", y: "231.41" },
    CHUBU: { text: "추부면", value: "CHUBU", x: "389.48", y: "162.96" },
    GUNBUK: { text: "군북면", value: "GUNBUK", x: "572", y: "193" },
    JINSAN: { text: "진산면", value: "JINSAN", x: "112.44", y: "399.26" },
    GEUMSEONG: { text: "금성면", value: "GEUMSEONG", x: "328", y: "389.48" },
    GEUMSAN: { text: "금산읍", value: "GEUMSAN", x: "348", y: "514" },
    JEWON: { text: "제원면", value: "JEWON", x: "558", y: "405" },
    NAMI: { text: "남이면", value: "NAMI", x: "228.15", y: "627.41" },
    NAMIL: { text: "남일면", value: "NAMIL", x: "422.07", y: "741.48" },
    BURI: { text: "부리면", value: "BURI", x: "600", y: "618" }
};
