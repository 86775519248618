import axios from "@/plugins/axios";
import dayjs from "dayjs";

let url = "/api/v1/me/mes/schedules";
import dummy from "./dummy.json";

export default {
    gets(data) {
        // return axios.get(url, data).then(result => result.data);
        return new Promise((resolve, reject) => {
            let [start, end] = data?.params?.dates || [];
            console.log({ start, end, dummy });
            let schedules = dummy.filter(({ date }) => !dayjs(date).isBefore(start, "date") && !dayjs(date).isAfter(end, "date"));
            setTimeout(() => resolve({ schedules }, Math.floor(Math.random() * 500 + 200)));
        });
    },
};
