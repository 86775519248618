var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "7",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('main-board-notice')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "5",
      "order": "3",
      "order-lg": "2"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('main-board-card', {
    attrs: {
      "title": "자유게시판",
      "icon": "/images/icon/icon-free.svg",
      "img": "/images/main/boards/board-free-background.jpg",
      "to": "/news/freeboard"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('main-board-card', {
    attrs: {
      "title": "보도자료",
      "icon": "/images/icon/icon-press.svg",
      "img": "/images/main/boards/board-press-background.jpg",
      "to": "/news/press"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "order": "1",
      "order-lg": "3"
    }
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "order": "3",
      "order-lg": "1"
    }
  }, [_c('router-link', {
    staticClass: "tit tit--sm font-weight-medium d-flex align-center",
    attrs: {
      "to": "/news/notice"
    }
  }, [_vm._v(" 더보기 "), _c('icon-link-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "order": "2"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "order": "1",
      "order-lg": "3"
    }
  }, [_c('div', {
    staticClass: "tit tit--md font-size-lg-40"
  }, [_c('div', {
    staticClass: "pointed-title"
  }, [_vm._v("금산의 생생한 소식과")]), _c('div', [_vm._v("주민의 목소리를 전달합니다.")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }