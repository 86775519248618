import Vue from "vue";
import dayjs from "dayjs";

import "dayjs/locale/ko";

import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.locale("ko");

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);

dayjs.tz.setDefault("Asia/Seoul");

/**
 * @param {Vue} Vue
 * @param {*} options
 */
const install = function(Vue, options) {
    Vue.prototype.$dayjs = dayjs;
};

Vue.use({ install });

export default dayjs;
