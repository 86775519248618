export function Header(){
    const HTML = document.querySelector('html');
    const HEADER = document.querySelector('.header');
    const MAIN = document.querySelector('.main');
    let windowWidth = window.outerWidth;
    let windowHeight = window.innerHeight;
    let mobileWidth = 1024;
    let scrollPos = 0;
    let ticking = false;
    let timeout = '';

    window.addEventListener('scroll', scroll, false);
    window.addEventListener("resize", resize, false);

    animate();

    function animate(call) {
        windowWidth = window.outerWidth;
        windowHeight = window.innerHeight;
        scrollPos = window.scrollY || window.pageYOffset;

        if(call === 'scroll'){
            headerSticky();
            // scrollTop();
        }
        if(call === 'resize'){

        }
        if(windowWidth > mobileWidth){
            burgerCreate();
        }
    }

    function scroll(){
        if (!ticking) {
            window.requestAnimationFrame(function() {
                animate('scroll');
                ticking = false;
            });
            ticking = true;
        }
    }

    function resize() {
        if ( !timeout ) {
            timeout = setTimeout(function() {
                timeout = null;
                animate('resize');
            }, 66);
        }
    }

    let burgerMenu = document.querySelector('.mobile-menu .button');
    let closeSidebar = document.querySelector('.mobile-sidebar__close');
    let dimmed = document.querySelector('.mobile-sidebar-dimmed');
    let mobileCategory = document.querySelectorAll('.mobile-category__list-link[data-toggle]');

    burgerMenu.addEventListener('click', burgerDestroy);
    closeSidebar.addEventListener('click', burgerCreate);
    dimmed.addEventListener('click', burgerCreate);

    function burgerCreate(){
        if(HEADER.getAttribute('data-sidebar') !== null){
            HTML.removeAttribute('data-scroll');
            HEADER.setAttribute('data-sidebar', 'closed');
            burgerMenu.setAttribute('aria-pressed', 'false');

            mobileCategory.forEach(element => {
                element.setAttribute('data-toggle', 'closed');
            });
        }
    }
    function burgerDestroy(){
        HTML.setAttribute('data-scroll', 'false');
        HEADER.setAttribute('data-sidebar', 'opened');
        this.setAttribute('aria-pressed', 'true');
    }

    mobileCategory.forEach(element => {
        element.addEventListener('click', function(){
            if(this.getAttribute('data-toggle') === 'closed')
                this.setAttribute('data-toggle', 'opened');
            else this.setAttribute('data-toggle', 'closed');
        });
    });

    // 모바일 사이드바 링크 클릭 시 html 스크롤 활성화
    (function(){
        const items = document.querySelectorAll('.mobile-sidebar a');
        items.forEach((item) => {
            item.addEventListener('click', defaultScroll);
        });
        function defaultScroll(){
            if(this.hasAttribute('href')){
                if(HTML.getAttribute('data-scroll') === 'false')
                    HTML.removeAttribute('data-scroll');
            }
        }
    })();

    // Sticky Header
    let primaryContainer = HEADER.querySelector('.header-primary-container');
    let primary = HEADER.querySelector('.header-primary');
    let secondaryContainer = HEADER.querySelector('.header-secondary-container');
    let secondary = HEADER.querySelector('.header-secondary');

    function headerCalculate(){
        let headerPosition = window.pageYOffset + HEADER.getBoundingClientRect().top,
            primaryHeight = primaryContainer.clientHeight,
            secondaryHeight;

        if(secondaryContainer === null)
            secondaryHeight = 0;
        else secondaryHeight = secondaryContainer.clientHeight;

        return{
            pos: headerPosition,            // 위치
            primaryHeight: primaryHeight,    // 높이
            secondaryHeight: secondaryHeight
        }
    }

    function headerSticky(){
        scrollPos = window.scrollY || window.pageYOffset;
        if(scrollPos > headerCalculate().pos){
            HEADER.setAttribute('data-header-sticky', 'sticky');
            primaryContainer.style.height = headerCalculate().primaryHeight + 'px';
            primary.style.position = 'fixed';
            if(secondaryContainer === null){
                return;
            }
            secondaryContainer.style.height = headerCalculate().secondaryHeight + 'px';
            secondary.style.position = 'fixed';
            secondary.style.top = headerCalculate().primaryHeight + 'px';
        }else{
            HEADER.removeAttribute('data-header-sticky');
            primaryContainer.removeAttribute('style');
            primary.removeAttribute('style');
            if(secondaryContainer === null){
                return;
            }
            secondaryContainer.removeAttribute('style');
            secondary.removeAttribute('style');
        }
    }

    // header 스타일변경
    HEADER.addEventListener('mouseover', HeaderOver);
    HEADER.addEventListener('mouseout', HeaderOut);
    window.addEventListener('scroll', HeaderScroll);
    window.addEventListener('scroll', HeaderMobileScroll);
    function HeaderOver(){
        this.classList.add('active');
    }
    function HeaderOut(){
        this.classList.remove('active');
    }
    function HeaderScroll() {
        if (window.scrollY >= 140) {
            HEADER.classList.add('scroll');
        } else {
            HEADER.classList.remove('scroll');
        }
    }
    function HeaderMobileScroll() {
        if (window.scrollY >= 50) {
            HEADER.classList.add('mobile-scroll');
        } else {
            HEADER.classList.remove('mobile-scroll');
        }
    }

    // let headerMenuListLink = HEADER.querySelectorAll('.header-menu-list');
    // for (var i = 0; i < headerMenuListLink.length; i++) {
    //     headerMenuListLink[i].addEventListener('mouseover', MenuLinkOver);
    //     headerMenuListLink[i].addEventListener('mouseout', MenuLinkOut);
    // }
    // function MenuLinkOver(){
    //     this.classList.add('active');
    //     HEADER.classList.add('link--on');
    // }
    // function MenuLinkOut(){
    //     this.classList.remove('active');
    //     HEADER.classList.remove('link--on');
    // }



    // 페이지 상단 이동
    // const scrollTopGap = 100;
    // let scrollTopFired = true;
    // function scrollTop(){
    //     if(scrollPos > scrollTopGap){
    //         if(scrollTopFired){
    //             scrollTopFired = false;
    //             var s = document.createElement("div");
    //             s.classList.add("scroll-top");
    //             s.innerHTML = "<button class='button' type='button' onclick='window.scrollTo(0, 0);'></button>";
    //             MAIN.appendChild(s);
    //         }
    //     }else{
    //         if(document.querySelector('.scroll-top') !== null){
    //             document.querySelector('.scroll-top').remove();
    //         }
    //         scrollTopFired = true;
    //     }
    // }
}
