import { MANAGER_SCOPES } from "../../assets/variables";

export const UserRoutes = [
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user/LevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/isWithdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user/WithdrawalList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/managers",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user/ManagerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user/UserList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.users.value],
    },
];
