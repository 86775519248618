import { BOARD_TYPES, MANAGER_SCOPES } from "../../assets/variables";

export const BoardRoutes = [
    {
        // 관리자 - 금산소식 - 공지사항
        path: "/console/board/notification",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardList.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NOTIFICATION.value }),
        scope: ["console", MANAGER_SCOPES.boardNotification.value],
    },
    {
        // 관리자 - 금산소식 - 공지사항
        path: "/console/board/notification/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NOTIFICATION.value }),
        scope: ["console", MANAGER_SCOPES.boardNotification.value],
    },
    {
        // 관리자 - 금산소식 - 공지사항
        path: "/console/board/notification/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NOTIFICATION.value }),
        scope: ["console", MANAGER_SCOPES.boardNotification.value],
    },
    //
    {
        // 관리자 - 금산소식 - 자유게시판
        path: "/console/board/community",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardList.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.COMMUNITY.value }),
        scope: ["console", MANAGER_SCOPES.boardCommunity.value],
    },
    {
        // 관리자 - 금산소식 - 자유게시판
        path: "/console/board/community/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.COMMUNITY.value }),
        scope: ["console", MANAGER_SCOPES.boardCommunity.value],
    },
    {
        // 관리자 - 금산소식 - 자유게시판
        path: "/console/board/community/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.COMMUNITY.value }),
        scope: ["console", MANAGER_SCOPES.boardCommunity.value],
    },
    //
    {
        // 관리자 - 금산소식 - 보도자료
        path: "/console/board/news",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardList.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NEWS.value }),
        scope: ["console", MANAGER_SCOPES.boardNews.value],
    },
    {
        // 관리자 - 금산소식 - 보도자료
        path: "/console/board/news/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NEWS.value }),
        scope: ["console", MANAGER_SCOPES.boardNews.value],
    },
    {
        // 관리자 - 금산소식 - 보도자료
        path: "/console/board/news/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/board/BoardView.vue"),
        props: (props) => ({ ...props, code: BOARD_TYPES.NEWS.value }),
        scope: ["console", MANAGER_SCOPES.boardNews.value],
    },
];
