var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--main"
  }, [_c('full-page', {
    ref: "fullpage",
    attrs: {
      "options": _vm.options,
      "id": "fullpage"
    }
  }, [_c('section', {
    staticClass: "section section--visual"
  }, [_c('main-visual')], 1), _c('section', {
    staticClass: "section"
  }, [_c('main-boards')], 1), _c('section', {
    staticClass: "section section--people"
  }, [_c('main-people')], 1), _c('section', {
    staticClass: "section section--map"
  }, [_c('main-map')], 1), _c('section', {
    staticClass: "section"
  }, [_c('main-archive')], 1), _c('section', {
    staticClass: "section"
  }, [_c('main-place')], 1), _c('section', {
    staticClass: "section section--footer py-0 fp-auto-height"
  }, [_c('main-footer')], 1)]), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }