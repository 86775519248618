var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "main-board-notice"
  }, [_c('div', {
    staticClass: "tit d-flex align-center mb-12 mb-md-16"
  }, [_c('v-img', {
    staticClass: "main-board-notice__icon",
    attrs: {
      "max-width": "36",
      "src": "/images/icon/icon-notice.svg"
    }
  }), _vm._v(" 공지사항 ")], 1), _c('v-simple-table', {
    staticClass: "main-board-notice__table",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("제목")]), _c('th', [_vm._v("작성일")])])]), _c('tbody', [!_vm.boards.length ? [_c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('div', {
          staticClass: "page-text text-center"
        }, [_vm._v("공지사항이 없습니다.")])])])] : [_vm._l(_vm.boards, function (board, index) {
          return [index < 4 ? _c('tr', {
            key: index,
            on: {
              "click": function () {
                return _vm.$router.push(`./news/notice/${board._id}`);
              }
            }
          }, [_c('td', {
            staticClass: "text-start"
          }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY-MM-DD")) + " ")])]) : _vm._e()];
        })]], 2)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }