import { ARCHIVING_BOARD_TYPES, MANAGER_SCOPES } from "../../assets/variables";

export const ArchivingRoutes = [
    {
        // 관리자 - 아카이빙 - 연혁
        path: "/console/archiving/history",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/HistoryList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.archivingHistory.value],
    },
    {
        // 관리자 - 아카이빙 - 프로젝트
        path: "/console/archiving/projects",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardList.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.PROJECT.value }),
        scope: ["console", MANAGER_SCOPES.archivingProject.value],
    },
    {
        // 관리자 - 아카이빙 - 프로젝트
        path: "/console/archiving/projects/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardView.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.PROJECT.value }),
        scope: ["console", MANAGER_SCOPES.archivingProject.value],
    },
    {
        // 관리자 - 아카이빙 - 프로젝트
        path: "/console/archiving/projects/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardView.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.PROJECT.value }),
        scope: ["console", MANAGER_SCOPES.archivingProject.value],
    },
    {
        // 관리자 - 아카이빙 - 갤러리
        path: "/console/archiving/gallery",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardList.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.GALLERY.value }),
        scope: ["console", MANAGER_SCOPES.archivingGallery.value],
    },
    {
        // 관리자 - 아카이빙 - 갤러리
        path: "/console/archiving/gallery/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardView.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.GALLERY.value }),
        scope: ["console", MANAGER_SCOPES.archivingGallery.value],
    },
    {
        // 관리자 - 아카이빙 - 갤러리
        path: "/console/archiving/gallery/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/archiving/BoardView.vue"),
        props: (props) => ({ ...props, code: ARCHIVING_BOARD_TYPES.GALLERY.value }),
        scope: ["console", MANAGER_SCOPES.archivingGallery.value],
    },
];
