var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer grey darken-4 page-text page-text--sm white--text"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-img', {
    staticClass: "mx-auto mb-18 mb-md-24",
    attrs: {
      "src": "/images/ci-footer.svg",
      "max-width": "300",
      "alt": "금산군 농촌신활력 플러스사업 추진단"
    }
  }), _c('footer-sns', {
    staticClass: "mb-12 mb-md-16"
  }), _c('footer-link', {
    staticClass: "mb-4 mb-md-8"
  }), _c('footer-information'), _c('p', {
    staticClass: "grey--text text--lighten-1 text-center mt-8 mt-md-16"
  }, [_vm._v("Copyright 2022. COMPANY CO.,Ltd. All Rights Reserved.")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }