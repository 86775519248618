<template>
    <div>
        <v-row justify="center" class="row--small">
            <v-col v-for="item in items" :key="item.title" cols="auto">
                <p class="grey--text text--lighten-1">{{ item.title }} {{ item.info }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                title: "주소",
                info: "충남 금산군 금산읍 비단로 382 2층 구, 아인리 646-9 2층, ‘빙고(삼돌이) 아이스크림’ 2층",
            },
            {
                title: "이메일",
                info: "gsshplus@naver.com",
            },
            {
                title: "팩스",
                info: "041-751-7883",
            },
            {
                title: "대표번호",
                info: "041-751-7883",
            },
            {
                title: "사업자등록번호",
                info: "111-11-11111",
            },
        ],
    }),
};
</script>