export const initCommonAttendance = (attendance = {}) => ({
    ...attendance,

    type: attendance.type ?? null,

    _user: attendance._user ?? null,

    isAttended: attendance.isAttended ?? null,

    attendedAt: attendance.attendedAt ?? null,
    departedAt: attendance.departedAt ?? null,
});
