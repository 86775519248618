import { RENTAL_LIMITATION_TARGET_TYPES, RENTAL_LIMITATION_TYPES } from "@/assets/variables";

export const initRentalLimitation = (limitation = {}) => ({
    ...(limitation || {}),

    type: limitation?.type ?? RENTAL_LIMITATION_TYPES.PER_DATE.value,

    dates: limitation?.dates ?? 0,
    hours: limitation?.hours ?? 0,
    times: limitation?.times ?? 0,
    resetDay: limitation?.resetDay ?? 0,
    isActive: limitation?.isActive ?? false,

    target: {
        ...(limitation?.target || {}),

        type: limitation?.target?.type ?? RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
        _subjects: [...(limitation?.target?._subjects || [])].sort(),
        subjectTypes: [...(limitation?.target?.subjectTypes || [])].sort(),
    },
});
