import blockages from "./blockages";
import limitations from "./limitations";
import schedules from "./schedules";
import subjects from "./subjects";

export default {
    blockages,
    limitations,
    schedules,
    subjects,
};
