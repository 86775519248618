import { MANAGER_SCOPES } from "../../assets/variables";

export const PeopleRoutes = [
    {
        // 관리자 - 팝업관리
        path: "/console/people",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/people/PeopleList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.people.value],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/people/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/people/PeopleView.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.people.value],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/people/:_people",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/people/PeopleView.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.people.value],
    },
];
