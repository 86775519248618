var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.name,
      attrs: {
        "cols": "auto"
      }
    }, [_c('router-link', {
      staticClass: "white--text",
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }