var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-link-arrow"
  }, [_vm._l(2, function (item) {
    return [_c('v-icon', {
      key: item,
      attrs: {
        "color": _vm.arrowColor
      }
    }, [_vm._v("mdi-chevron-right")])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }