import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export const initRentalRequest = (request = {}) => ({
    ...request,

    user: request.user ?? null,
    subject: request.subject ?? null,

    rentalType: request.rentalType ?? null,
    subjectType: request.subjectType ?? null,

    date: request.date ?? null,
    desc: {
        ...(request.desc ?? {}),
        objective: request.desc?.objective ?? null,
        procedure: request.desc?.procedure ?? null,
        substance: request.desc?.substance ?? null,
        headcount: request.desc?.headcount ?? null,
        rejection: request.desc?.rejection ?? null,
    },

    state: request.state ?? RENTAL_SCHEDULE_STATES.APPLIED.value,
});
