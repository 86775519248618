import axios from "@/plugins/axios";

import bans from "./bans";
import equipments from "./equipments";

let url = "/api/console/users";

export default {
    bans,
    equipments,

    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    avatar: {
        post({ _user }, file) {
            var headers = { "Content-Type": "multipart/form-data" };
            var formData = new FormData();
            formData.append("file", file);
            return axios.post(`${url}/${_user}/avatar`, formData, { headers }).then((result) => result.data);
        },
        delete(data) {
            return axios.delete(`${url}/${data._user}/avatar/${data._id}`).then((result) => result.data);
        },
    },
    brochures: {
        post({ _user, index }, file) {
            var headers = { "Content-Type": "multipart/form-data" };
            var formData = new FormData();
            if (index !== undefined) formData.append("index", index);
            formData.append("file", file);
            return axios.post(`${url}/${_user}/brochures`, formData, { headers }).then((result) => result.data);
        },
        delete(data) {
            return axios.delete(`${url}/${data._user}/brochures/${data._id}`).then((result) => result.data);
        },
    },
};
