import Vue from "vue";
import Vuex from "vuex";

import api from "../api";
import axios from "../plugins/axios";
import dayjs from "../plugins/vue-dayjs";
import { initCommonSetting } from "../assets/variables";

Vue.use(Vuex);

var refreshToken = localStorage.getItem("refreshToken");
var accessToken = localStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

export default new Vuex.Store({
    state: {
        // common
        setting: initCommonSetting(),

        // user
        refreshToken,
        accessToken,
        payload,
        scope,
        user: undefined,

        // join
        agreements: JSON.parse(sessionStorage.getItem("agreements") || "[]"),
        areas: [],

        // shop
        _carts: JSON.parse(sessionStorage.getItem("_carts") || "[]"), // 장바구니 상품
        _pickups: JSON.parse(sessionStorage.getItem("_pickups") || "[]"), // 주문 픽업상품

        // rental
        bans: [],
    },
    mutations: {
        // common
        setSetting(state, { setting }) {
            state.setting = initCommonSetting(setting);
        },

        // user
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("accessToken", accessToken);
        },
        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];

            axios.defaults.headers.common["Authorization"] = "";

            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
        },
        setUser(state, { user }) {
            state.user = user;
        },

        // join
        agreements(state, agreements) {
            state.agreements = agreements;

            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        setAreas(state, { areas }) {
            state.areas = areas;
        },

        // shop
        addToCart(state, _carts) {
            state._carts = _carts;
            sessionStorage.setItem("_carts", JSON.stringify(_carts));
        },
        checkout(state, _pickups) {
            state._pickups = _pickups;
            sessionStorage.setItem("_pickups", JSON.stringify(_pickups));
        },

        // bans
        setBans(state, { bans }) {
            state.bans = bans;
        },
    },
    actions: {
        // common
        async getSetting__v1({ commit }) {
            const { setting } = await api.v1.setting.get();
            commit("setSetting", { setting });
        },
        async getSetting__console({ commit }) {
            const { setting } = await api.console.setting.get();
            commit("setSetting", { setting });
        },

        // user
        async login({ commit }, { username, password }) {
            var { refreshToken } = await api.auth.getRefreshToken({ username, password });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
        logout({ commit }) {
            commit("logout");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
        async getUser({ commit, state }) {
            if (!state.payload?._user) return;

            try {
                const { user } = await api.v1.me.get();
                commit("setUser", { user });
            } catch (error) {
                console.error(error);
            }
        },

        // join
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },
        async getAreas({ commit }) {
            try {
                const { areas } = await api.console.areas.gets();
                commit("setAreas", { areas });
            } catch (error) {
                console.error(error);
            }
        },

        //shop
        addToCart({ commit }, _carts) {
            commit("addToCart", _carts);
        },
        checkout({ commit }, _pickups) {
            commit("checkout", _pickups);
        },

        //
        async getBans({ commit, state }) {
            if (!state.payload?._user) return;

            const { bans } = await api.v1.me.bans.gets();
            commit("setBans", { bans });
        },
    },
    getters: {
        isBanned(state) {
            return (state?.bans || []).some(({ startsAt, endsAt }) => {
                const isStarted = startsAt ? dayjs(startsAt).subtract(1, "day").isBefore(dayjs()) : true;
                const isEnded = endsAt ? dayjs().isAfter(endsAt) : false;
                return isStarted && !isEnded;
            });
        },
    },
    modules: {},
});
