import { PEOPLE_TYPES } from "../constants";

export const initPeople = (people = {}) => ({
    ...people,
    // _id: people?._id || null,
    _thumb: people?._thumb || null,
    thumb: people?.thumb || null,

    type: people?.type || PEOPLE_TYPES.GROUP.value,
    index: people?.index || 0,
    name: people?.name || null,
    summary: people?.summary || null,
    details: (people?.details || []).map(initDetails),
    
    isMain: people?.isMain || false,
});

// export const initPeople_group = (group = {}) => ({
//     ...initPeople(group),
// });

// export const initPeople_member = (member = {}) => ({
//     ...initPeople(member),
//     _images: member?._images || null,
//     images: member?.images || [],

//     _group: member?._group || null,
//     group: member?.group || {},
// });

export const initDetails = (detail = {}) => ({
    ...detail,

    name: detail?.name || null,
    desc: detail?.desc || null,
});

export const initOrganizations = (organization = {}) => ({
    ...organization,

    name: organization?.name || null,
    desc: organization?.desc || null,
    phone: organization?.phone || null,
});
