var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab' ? _c('div', {
    class: _vm.className
  }, [_c('v-container', [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.sh ? _c('v-tabs', {
      key: item.title,
      attrs: {
        "height": _vm.$vuetify.breakpoint.mdAndUp ? '64' : '52',
        "hide-slider": ""
      }
    }, _vm._l(item.children, function (child) {
      return _c('v-tab', {
        key: child.title,
        class: {
          'v-tab--active': _vm.tabActive == child.title
        },
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 1) : _vm._e()];
  })], 2)], 1) : _vm.className == 'gnb' ? _c('ul', {
    staticClass: "header-menu-lists"
  }, [_vm._l(_vm.gnbs, function (item) {
    return [_c('li', {
      key: item.title,
      staticClass: "header-menu-list"
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), item.children ? [_c('ul', {
      staticClass: "header-menu-sub"
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        staticClass: "sub-link",
        attrs: {
          "to": child.path
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])])], 1);
    }), 0)] : _vm._e()], 2)];
  })], 2) : _vm.className == 'gnb-mo' ? _c('ul', {
    staticClass: "mobile-category__lists"
  }, [_vm._l(_vm.gnbs, function (item) {
    return [_c('li', {
      key: item.title,
      staticClass: "mobile-category__list",
      attrs: {
        "data-category-order": ""
      }
    }, [item.children ? [_c('div', {
      staticClass: "mobile-category__list-link",
      attrs: {
        "data-toggle": "closed"
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "arrow"
    })]), _c('ul', {
      staticClass: "mobile-category__level"
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        staticClass: "mobile-category__level-link",
        attrs: {
          "to": child.path
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])])], 1);
    }), 0)] : [_c('router-link', {
      staticClass: "mobile-category__list-link",
      attrs: {
        "to": item.path
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))])])]], 2)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }