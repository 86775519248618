export const initDataTableHeaders = (headers = []) => {
    return headers.map((item, index, array) => ({
        ...item,

        formatter: item.formatter ?? ((value) => value ?? "-"),

        align: item.align ?? "start",
        divider: index != array.length - 1,

        class: (item.class || "") + " white-space-pre-line",
        cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line",
    }));
};
