export * from "./archiving.constants";
export * from "./common-attendances";
export * from "./programs.constants";
export * from "./rentals.constants";
export * from "./users.constants";
export * from "./story.constants";


export const EXPERT_DESCRIPTION_TERMS = ["기술정보", "학위", "지식재산권", "논문", "제품", "서비스", "기타"];

export const SERVICE_PROGRAM_CATEGORIES = {
    UNIT: { text: "상시지원", value: "UNIT" },
    TOTAL: { text: "공모지원", value: "TOTAL" },
};

export const BOARD_TYPES = {
    NOTIFICATION: { text: "공지사항", value: "NOTIFICATION" },
    COMMUNITY: { text: "자유게시판", value: "COMMUNITY" },
    NEWS: { text: "보도자료", value: "NEWS" },
};

export const PEOPLE_TYPES = {
    GROUP: { value: "GROUP", text: "그룹" },
    MEMBER: { value: "MEMBER", text: "구성원" },
};