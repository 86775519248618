import axios from "@/plugins/axios";

let url = (data) => "/api/console/services/${_program}/files".interpolate(data);

export default {
    post({ _program, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_program) formData.append("_program", _program);
        if (index !== undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(url({ _program }), formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
