import Vue from "vue";
import Vuetify from "vuetify";

// Translation provided by Vuetify (javascript)
import { ko } from "vuetify/src/locale";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#FFF6E5",
                lighten4: "#FEE8BF",
                lighten3: "#FED894",
                lighten2: "#FDC869",
                lighten1: "#FCBD49",
                base: "#FCB129",
                darken1: "#FCAA24",
                darken2: "#FBA11F",
                darken3: "#FB9819",
                darken4: "#FA880F",
            },
            secondary: {
                lighten5: "#EBF2F9",
                lighten4: "#CCE0F1",
                lighten3: "#ABCBE8",
                lighten2: "#89B6DE",
                lighten1: "#6FA6D7",
                base: "#5696D0",
                darken1: "#4F8ECB",
                darken2: "#4583C4",
                darken3: "#3C79BE",
                darken4: "#2B68B3",
            },
            tertiary: {
                lighten5: "#FDECF1",
                lighten4: "#F8AFC4",
                lighten3: "#F58EAC",
                lighten2: "#F8AFC4",
                lighten1: "#F2769B",
                base: "#F05E89",
                darken1: "#EE5681",
                darken2: "#EC4C76",
                darken3: "#E9426C",
                darken4: "#E53159",
            },
            grey: {
                lighten5: "#F7F7F7",
                lighten4: "#ECECEC",
                lighten3: "#DDDDDD",
                lighten2: "#ACB1BC",
                lighten1: "#999999",
                base: "#666666",
                darken1: "#555555",
                darken2: "#444444",
                darken3: "#333333",
                darken4: "#111111",
            },
            error: "#CE6565",
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00a4e0",
            secondary: "#445163",
            accent: "#00a4e0",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function(theme) {
    return new Vuetify({
        lang: {
            locales: { ko },
            current: "ko",
        },
        theme: theme || basicTheme,
    });
}
