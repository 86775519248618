var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-archive"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('v-row', [_vm._l(_vm.boards, function (board, index) {
    var _board$thumb;
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "archive-card",
      attrs: {
        "flat": "",
        "to": `./archiving/project/${board === null || board === void 0 ? void 0 : board._id}`
      }
    }, [_c('div', {
      staticClass: "archive-card__inner"
    }, [_c('v-card', {
      staticClass: "archive-card__image",
      attrs: {
        "img": board === null || board === void 0 ? void 0 : (_board$thumb = board.thumb) === null || _board$thumb === void 0 ? void 0 : _board$thumb.src
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    })], 1)], 1)])], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-lg-none",
    attrs: {
      "order": "2"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "lg": "12",
      "order": "3",
      "order-lg": "1"
    }
  }, [_c('router-link', {
    staticClass: "tit tit--sm font-weight-medium d-flex align-center justify-end",
    attrs: {
      "to": "/archiving/history"
    }
  }, [_vm._v(" 더보기 "), _c('icon-link-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block"
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--md font-size-lg-40 pointed-title pointed-title--secondary"
  }, [_vm._v("금산의 새로운 바람, "), _c('strong', [_vm._v("신활력 현장")]), _vm._v("을 "), _c('strong', [_vm._v("기록")]), _vm._v("합니다.")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }