import axios from "@/plugins/axios";
import dayjs from "dayjs";

let url = "/api/console/mes/dashboard";
import dummy from "./dummy.json";

export default {
    gets(data) {
        // return axios.get(url, data).then(result => result.data);
        return new Promise((resolve, reject) => {
            let [start, end] = data?.params?.dates || [];
            console.log({ start, end, dummy });
            let schedules = dummy.filter(({ date }) => !dayjs(date).isBefore(start, "date") && !dayjs(date).isAfter(end, "date"));
            setTimeout(() => resolve({ schedules }, Math.floor(Math.random() * 500 + 200)));
        });
    },
    get(data) {
        // return axios.get(`${url}/${data._id}`).then(result => result.data);
        let { date, code } = data?.params || {};
        return new Promise((resolve, reject) => {
            let schedule = dummy.find((item) => item.date == date && item.code == code);
            setTimeout(() => resolve({ schedule }), Math.floor(Math.random() * 500 + 200));
        });
    },
};
