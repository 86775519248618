import { USER_GENDER_TYPES } from "@/assets/variables";
import { MANAGER_SCOPES, USER_MARKETING_METHODS, USER_TYPES } from "../constants";

export const initAddress = (address = {}) => ({
    ...address,

    postcode: address?.postcode || null,
    address1: address?.address1 || null,
    address2: address?.address2 || null,
});

export const initManager = (form = {}) => ({
    ...(form || {}),

    username: form?.username ?? null,
    password: form?.password ?? null,
    subPassword: form?.subPassword ?? null,

    scope: [...(form?.scope ?? [MANAGER_SCOPES.manager.value])],

    type: USER_TYPES.MANAGER,
});

export const initUser = (user = {}) => ({
    ...user,

    // type: user.type ?? USER_TYPES.INDIVIDUAL,
    // kind: user.kind ?? INDIVIDUAL_KINDS.ELSE.value,

    _terms: user?._terms || [],
    _avatar: user?._avatar || null,

    username: user.username ?? null,
    password: user.password ?? null,

    name: user.name ?? null,
    nickname: user?.nickname || null,
    gender: user?.gender || USER_GENDER_TYPES.MALE.value,
    phone: user.phone ?? null,
    birthday: user?.birthday || null,
    email: user.email ?? null,
    marketingMethod: user?.marketingMethod || USER_MARKETING_METHODS.EMAIL.value,

    // blacksmith: {
    //     ...(user.blacksmith || {}),

    //     _equipments__rentable: [...(user.blacksmith?._equipments__rentable ?? [])],
    //     equipments__rentable: [...(user.blacksmith?.equipments__rentable ?? [])],

    //     _equipmentUseConsent: user.blacksmith?._equipmentUseConsent ?? null,
    //     equipmentUseConsent: user.blacksmith?.equipmentUseConsent ?? null,

    //     insurance: {
    //         ...(user.blacksmith?.insurance || {}),

    //         policies: [...(user.blacksmith?.insurance?.policies || [])],
    //         expiresAt: user.blacksmith?.insurance?.expiresAt?.toDate?.() ?? null,
    //     },

    //     facility: {
    //         ...(user.blacksmith?.facility || {}),
    //         isActive: user.blacksmith?.facility?.isActive ?? false,
    //     },
    // },
});

// export const initUserOrganization = (user = {}) => ({
//     ...initUserIndividual(user),

//     type: user.type ?? USER_TYPES.ORGANIZATION,
//     kind: undefined,

//     companyName: user.companyName ?? null,
//     businessNumber: user.businessNumber ?? null,

//     _businessRegistration: user._businessRegistration ?? null,
//     businessRegistration: user.businessRegistration ?? null,
// });
