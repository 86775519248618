var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-primary-container"
  }, [_c('div', {
    staticClass: "header-primary"
  }, [_c('div', {
    staticClass: "header-primary__contents"
  }, [_c('div', {
    staticClass: "header-primary__forward"
  }, [_c('div', {
    staticClass: "header-logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("금산군 농촌신활력 플러스사업")])])], 1)]), _vm._m(0)]), _c('div', {
    staticClass: "pc-menu"
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('client-gnb', {
    staticClass: "pl-140",
    attrs: {
      "className": "gnb"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button', {
    staticClass: "header-customer-lists"
  })], 1)], 1)], 1)])])]), _c('div', {
    staticClass: "mobile-sidebar"
  }, [_vm._m(1), _c('div', {
    staticClass: "mobile-sidebar__link"
  }, [_c('gnb-login-button', {
    staticClass: "mobile-gnb-link"
  })], 1), _c('div', {
    staticClass: "mobile-sidebar__body"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb-mo"
    }
  })], 1)]), _c('div', {
    staticClass: "mobile-sidebar-dimmed"
  })]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-menu"
  }, [_c('button', {
    staticClass: "button",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span')])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-sidebar__head"
  }, [_c('button', {
    staticClass: "mobile-sidebar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("모바일 메뉴바 닫기")])])]);

}]

export { render, staticRenderFns }