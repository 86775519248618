import axios from "@/plugins/axios";

let url = "/api/v1/people";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}
