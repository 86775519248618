<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <v-tabs v-if="item.title == sh" :key="item.title" :height="$vuetify.breakpoint.mdAndUp ? '64' : '52'" hide-slider>
                    <v-tab v-for="child in item.children" :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</v-tab>
                </v-tabs>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" class="header-menu-lists">
        <template v-for="item in gnbs">
            <li class="header-menu-list" :key="item.title">
                <router-link :to="item.path" class="link"
                    ><h2>{{ item.title }}</h2></router-link
                >
                <template v-if="item.children">
                    <ul class="header-menu-sub">
                        <li v-for="child in item.children" :key="child.title">
                            <router-link :to="child.path" class="sub-link"
                                ><span>{{ child.title }}</span></router-link
                            >
                        </li>
                    </ul>
                </template>
            </li>
        </template>
    </ul>
    <ul v-else-if="className == 'gnb-mo'" class="mobile-category__lists">
        <template v-for="item in gnbs">
            <li class="mobile-category__list" data-category-order :key="item.title">
                <template v-if="item.children">
                    <div class="mobile-category__list-link" data-toggle="closed">
                        <span>{{ item.title }}</span>
                        <div class="arrow"></div>
                    </div>
                    <ul class="mobile-category__level">
                        <li v-for="child in item.children" :key="child.title">
                            <router-link :to="child.path" class="mobile-category__level-link"
                                ><span>{{ child.title }}</span></router-link
                            >
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <router-link :to="item.path" class="mobile-category__list-link">
                        <span>{{ item.title }}</span>
                    </router-link>
                </template>
            </li>
        </template>
    </ul>
</template>

<script>
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

export default {
    props: {
        className: String,
        indexNum: String,
        sh: String,
        tabActive: String,
    },
    data: function () {
        return {
            RENTAL_SUBJECT_TYPES,
            gnbs: [
                {
                    title: "소개",
                    path: "/about/greetings",
                    children: [
                        {
                            title: "인사말",
                            path: "/about/greetings",
                        },
                        {
                            title: "사업소개",
                            path: "/about/business",
                        },
                        {
                            title: "사업추진단",
                            path: "/about/team",
                        },
                        {
                            title: "오시는 길",
                            path: "/about/location",
                        },
                    ],
                },
                {
                    title: "금산에 활력을 불어넣는 사람들",
                    path: "/people",
                },
                {
                    title: "금산마을 이야기",
                    path: "/story",
                },
                {
                    title: "아카이빙",
                    path: "/archiving/history",
                    children: [
                        {
                            title: "연혁",
                            path: "/archiving/history",
                        },
                        {
                            title: "프로젝트",
                            path: "/archiving/project",
                        },
                        {
                            title: "갤러리",
                            path: "/archiving/gallery",
                        },
                    ],
                },
                {
                    title: "금산소식",
                    path: "/news/notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/news/notice",
                        },
                        {
                            title: "자유게시판",
                            path: "/news/freeboard",
                        },
                        {
                            title: "보도자료",
                            path: "/news/press",
                        },
                    ],
                },
                {
                    title: "공간",
                    path: "/place/introduction",
                    children: [
                        {
                            title: "공간소개",
                            path: "/place/introduction",
                        },
                        {
                            title: "대관신청",
                            path: "/place/reservation",
                        },
                    ],
                },
                // {
                //     title : "소개",
                //     path : "/about/center",
                //     children : [
                //         {
                //             title : "용산 시제품제작소 소개",
                //             path : "/about/center#sub-tab",
                //         },
                //         {
                //             title : "공간소개",
                //             path : "/about/facilities#sub-tab",
                //         },
                //         {
                //             title : "입주현황",
                //             path : "/about/current#sub-tab",
                //         },
                //         {
                //             title : "이용안내",
                //             path : "/about/information#sub-tab",
                //         },
                //     ]
                // },
                // {
                //     title : "프로그램",
                //     path : "/program/list",
                //     children : [
                //         {
                //             title : "프로그램 신청",
                //             path : "/program/list#sub-tab",
                //         },
                //         {
                //             title : "프로그램 안내",
                //             path : "/program/info#sub-tab",
                //         },
                //     ]
                // },
                // {
                //     title : "장비 및 공간",
                //     path : `/rental/${RENTAL_SUBJECT_TYPES.EQUIPMENTS.value.toLowerCase()}`,
                //     children : [
                //         {
                //             title : "메이커스 장비예약",
                //             path : `/rental/${RENTAL_SUBJECT_TYPES.EQUIPMENTS.value.toLowerCase()}#sub-tab`,
                //         },
                //         {
                //             title : "시설 및 공간예약",
                //             path : `/rental/${RENTAL_SUBJECT_TYPES.FACILITIES.value.toLowerCase()}#sub-tab`,
                //         },
                //         {
                //             title : "전문제조장비",
                //             path : "/rental/intro#sub-tab",
                //         },
                //     ]
                // },
                // {
                //     title : "게시판",
                //     path : "/community/notice",
                //     children : [
                //         {
                //             title : "공지사항",
                //             path : "/community/notice#sub-tab",
                //         },
                //         {
                //             title : "건의합니다",
                //             path : "/community/proposal#sub-tab",
                //         },
                //         {
                //             title : "1:1문의",
                //             path : "/community/inquiry#sub-tab",
                //         },
                //     ]
                // },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.header-menu-lists {
    .header-menu-list {
        &:hover {
            .link {
                color: var(--v-primary-base);
            }
        }
    }
}
</style>