import dayjs from "@/plugins/vue-dayjs";

export const initProgramUnit = (unit = {}) => ({
    ...unit,

    // virtuals
    day: unit.date ? dayjs(unit.date).format("dddd") : null,
    date: (unit.date ?? Date.now()).toDate(),
    timeEnd: unit.timeEnd ?? dayjs().startOf("hour").add(1, "hour").format("HH:mm"),
    timeStart: unit.timeStart ?? dayjs().startOf("hour").format("HH:mm"),

    tempId: unit.tempId ?? Math.random(),
});

export const initProgram = (program = {}) => ({
    ...program,

    _thumb: program._thumb ?? null,

    _units: [...(program._units ?? [])],
    units: [...(program.units ?? [])],

    _category: program._category ?? null,

    _subjects: [...(program._subjects ?? [])],
    subjects: [...(program.subjects ?? [])],

    _attendees: [...(program._attendees ?? [])],
    _applications: [...(program._applications ?? [])],

    units: [...(program.units ?? [])].map(initProgramUnit),

    name: program.name ?? null,
    shows: program.shows ?? null,
    detail: program.detail ?? null,
    endsAt: program.endsAt ?? null,
    startsAt: program.startsAt ?? null,
    capacity: program.capacity ?? null,
    userTypes: [...(program.userTypes ?? [])],

    display: {
        ...(program.display || {}),
        endsAt: program.display?.endsAt || null,
        startsAt: program.display?.startsAt || null,
    },

    application: {
        ...(program.application || {}),
        endsAt: program.application?.endsAt || null,
        startsAt: program.application?.startsAt || null,
    },
});

export const initProgramApplication = (application = {}) => ({
    ...application,

    _user: application._user ?? null,
    _program: application._program ?? null,

    program: initProgram(application.program),

    state: application.state ?? null,
});
