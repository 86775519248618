export const initCommonSetting = (setting = {}) => ({
    ...setting,

    rental: {
        ...(setting.rental || {}),
        equipment: {
            ...(setting.rental?.equipment || {}),
            places: [...(setting.rental?.equipment?.places || [])],
        },
    },
});
