export const inputAttrs = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const switchAttrs = {
    inset: true,
    hideDetails: "auto",
    class: "pa-0 ma-0 ml-1",
};

export const attrs_table = {
    "disable-sort": true,
    "disable-pagination": true,
    "hide-default-footer": true,
};

export const attrs_input__verticalTable = {
    flat: true,
    solo: true,
    class: "rounded-0",
    hideDetails: true,
    "persistent-placeholder": true,
};
