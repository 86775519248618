import axios from "@/plugins/axios";

let url = "/api/v1/rental/limitations/";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get({ type, targetType } = {}) {
        if (!type) throw new Error("조회할 제한유형을 선택해주세요");
        if (!targetType) throw new Error("조회할 대상유형을 선택해주세요");

        return axios.get(`${url}/type/${type}/target-type/${targetType}`).then((result) => result.data);
    },
};
