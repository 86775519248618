import { MANAGER_SCOPES } from "../../assets/variables";

export const StoryRoutes = [
    {
        // 관리자 - 금산마을 이야기
        path: "/console/story",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/story/StoryList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.story.value],
    },
    {
        // 관리자 - 금산마을 이야기 작성
        path: "/console/story/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/story/StoryView.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.story.value],
    },
    {
        // 관리자 - 금산마을 이야기 작성
        path: "/console/story/:_story",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/story/StoryView.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.story.value],
    },
];
