<template>
    <client-page class="wrap--main">
        <full-page ref="fullpage" :options="options" id="fullpage">
            <section class="section section--visual">
                <main-visual />
            </section>

            <section class="section">
                <main-boards />
            </section>

            <section class="section section--people">
                <main-people />
            </section>

            <section class="section section--map">
                <main-map />
            </section>

            <section class="section">
                <main-archive />
            </section>

            <section class="section">
                <main-place />
            </section>

            <section class="section section--footer py-0 fp-auto-height">
                <main-footer />
            </section>
        </full-page>

        <popup-layer />
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import MainBoards from "@/components/client/main/main-boards.vue";
import MainPeople from "@/components/client/main/main-people.vue";
import MainMap from "@/components/client/main/main-map.vue";
import MainArchive from "@/components/client/main/main-archive.vue";
import MainPlace from "@/components/client/main/main-place.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainVisual,
        MainFooter,

        MainBoards,
        MainPeople,
        MainMap,
        MainArchive,
        MainPlace,
    },
    props: {},
    data: () => ({
        options: {
            responsiveWidth: 1464,
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-main__wrap {
        padding-top: 0;
    }
}
.fp-section.section {
    padding: 0;
    overflow: hidden;
    &--map {
        background-image: url(/images/main/map/main-map-background.png);
        background-size: cover;
    }
    &--people {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100vw;
            height: 50%;
            background-color: var(--v-primary-base);
            z-index: -1;
        }
    }
}
@media (max-width: 1463px) {
    .fp-section.section {
        padding: 60px 0;
        height: auto !important;
        ::v-deep {
            .fp-tableCell {
                height: auto !important;
            }
        }
        &--visual {
            padding-top: 0;
            margin-top: 50px;
        }
        &--footer {
            display: none;
        }
    }
}
</style>
